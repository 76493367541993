@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anta&family=Madimi+One&family=Mirza:wght@400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url(https://db.onlinewebfonts.com/c/125063e353c1f65c55ba1e3811969217?family=GE+SS+Two+Medium);
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap");

html,
body,
#root {
	height: 100vh;
	width: 100%;
	font-family: "Montserrat", sans-serif;
	padding: 0;
	margin: 0;
	background-color: #f3f7ff;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar {
	right: 0;
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: #164c68;
	border-radius: 8px;
	width: 6px;
	height: 6px;
}

.rbc-event {
	/* Apply styles to the events */
	background-color: #164c68 !important;
	color: white;
}

.rbc-event:focus {
	outline: none !important;
}
.css-1e7j6lv-MuiFormLabel-root-MuiInputLabel-root {
	color: #164c68 !important;
	font-size: 1.2rem;
	z-index: 20;
}

.MuiFormLabel-root {
	color: #164c68 !important;
}

.css-9425fu-MuiOutlinedInput-notchedOutline {
	border-color: #164c68 !important;
}

.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
	background-color: #164c68 !important;
}

.css-1gdyttw-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input {
	padding: 8px !important;
}

.MuiDataGrid-columnHeaderTitle {
	color: #164c68 !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
	color: #164c68 !important;
}

.css-c752xu-MuiButtonBase-root-MuiButton-root {
	color: #fff !important;
}

.MuiSelect-icon {
	color: #164c68 !important;
}

.css-xepioh .ant-empty-img-3 {
	fill: #164c68 !important;
}

.css-xepioh .ant-empty-img-4 {
	fill: #f8f9fa !important;
}

.css-xepioh .ant-empty-img-2 {
	fill: #b1b1b1 !important;
}

.css-xyyaop-MuiButtonBase-root-MuiTab-root.Mui-selected {
	color: #164c68 !important;
}

.css-kmthmi-MuiFormControl-root-MuiTextField-root .MuiIconButton-root {
	color: #164c68 !important;
}

.css-xyyaop-MuiButtonBase-root-MuiTab-root,
.css-9032ii-MuiButtonBase-root-MuiTab-root {
	color: #1f2021 !important;
}

.quill {
	width: 100%;
	min-height: 300px;
}

.ql-container {
	min-height: 300px;
}

.css-l2j1wl-MuiButtonBase-root-MuiFab-root {
	background-color: #164c68 !important;
	color: #fff !important;
}

.css-l2j1wl-MuiButtonBase-root-MuiFab-root:hover {
	background-color: #fff !important;
	color: #164c68 !important;
}
.css-bw206g-MuiTabPanel-root {
	padding: 8px !important;
}

.css-73xkmm-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
	color: #164c68 !important;
}

.MuiDataGrid-menuIconButton {
	color: #164c68 !important;
	visibility: visible !important;
}

.css-jkymtx-MuiPaper-root {
	background-color: #164c68 !important;
}

.MuiDataGrid-panelWrapper {
	background-color: #164c68 !important;
}

.YourHighlightClass {
	background-color: yellow;
}

#party-select {
	padding: 4px !important;
	color: #164c68 !important;
}

#party-select-listbox {
	background-color: #164c68 !important;
}

#phone-number-label {
	color: #164c68 !important;
}

#phone-number {
	color: #164c68 !important;
}

#phone-number-listbox {
	background-color: #164c68 !important;
}

.css-1n8xjfr-MuiFormControl-root {
	min-width: 150px !important;
	text-align: center;
}

#constraint-select-listbox {
	background-color: #164c68 !important;
}

.css-1y7k1sw {
	/* border: 0.5px solid #164c68 !important; */
	color: #164c68 !important;
}

.css-nqlg3w {
	border: 0.5px solid #164c68 !important;
}

/* #type-select-small {
	border: 0.5px solid #164c68 !important;
} */

.MuiAutocomplete-inputRoot {
	width: 100% !important;
	padding: 2px !important;
	/* border: 0.5px solid #164c68 !important; */
	color: #164c68 !important;
}
/* .MuiAutocomplete-input {
	text-align: center !important;
} */

#combo-box-demo {
	text-align: center !important;
}

.MuiList-root {
	background-color: #424242 !important;
}

.css-1h51icj-MuiAutocomplete-root {
	width: 100% !important;
	padding: 0px !important;
}

.css-1xnbq41-MuiAutocomplete-root {
	width: 35% !important;
}

.css-chq866 {
	width: 35% !important;
}

.css-dovo2p {
	color: #164c68 !important;
}

.css-1yzqhai {
	color: #164c68 !important;
}

.css-c5w09g {
	color: #164c68 !important;
}

.MuiTablePagination-select {
	border-color: #fff !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	color: #000 !important;
}

.css-10wipka .MuiMenu-paper {
	padding: 0px !important;
}

.css-1eytq3z {
	padding: 12px !important;
}

#image-email::before {
	content: "";
	display: inline-block;
	width: 20px; /* adjust as needed */
	height: 20px; /* adjust as needed */
	background-image: url("./components/Assets/Files/image.png");
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 5px;
}

.Mui-disabled {
	background-color: #a9a9a924 !important;
}

.css-1gvw6tf:disabled {
	background-color: #fff !important;
}

.MuiCircularProgress-root {
	color: #00a3b1 !important;
}

input:disabled {
	background-color: #fff !important;
	color: #164c68 !important;
}

.css-dop7a3 {
	background-color: #00a3b1 !important;
	border-radius: 8px;
}

.css-12ch4js {
	background-color: #fff !important;
}

.MuiAutocomplete-listbox {
	background-color: #164c68 !important;
	color: #fff !important;
}

#meetingUrl {
	min-width: 250px !important;
}

.css-5luc86 {
	color: #164c68 !important;
}

.css-5luc86 > .MuiTab-iconWrapper {
	margin-left: 8px !important;
}

.css-1rx2nbs {
	color: #164c68 !important;
}

.MuiTabs-flexContainerVertical {
	gap: 3rem !important;
}

#reminderDay-textfield {
	padding: 8.5px 14px !important;
	max-width: 60px;
}
#reminderHour-textfield {
	padding: 8.5px 14px !important;
	max-width: 60px;
}

.MuiFormControlLabel-label {
	color: #164c68;
}

.MuiRadio-root {
	color: #164c68 !important;
	margin-right: 0.5rem !important;
}

.MuiTab-root {
	color: #164c68 !important;
}

/* Mui React Table */

/* pagination */
.css-ii9ivn {
	color: #164c68 !important;
}

/* toolbar */
.css-1ycxt2a-MuiButtonBase-root-MuiIconButton-root {
	color: #164c68 !important;
}

/* search input */
.css-wu1.css-8nj4dk-MuiInputBase-root-MuiOutlinedInput-rooti5n {
	color: #164c68 !important;
}

.css-1vfqh1u-MuiInputBase-input-MuiOutlinedInput-input {
	color: #164c68 !important;
}

.css-193ajjd-MuiInputBase-input-MuiInput-input {
	color: #164c68 !important;
}

.css-1v8rop0-MuiButtonBase-root-MuiIconButton-root {
	color: #164c68 !important;
}

.css-z83gb {
	color: #164c68 !important;
}

/* table header */
.css-dlu8na {
	background-color: #fff !important;
}

.Mui-TableHeadCell-Content {
	color: #164c68 !important;
}

.css-309wxh {
	background-color: #fff !important;
}

/* table body */
.MuiTableCell-body {
	color: #164c68 !important;
}

.MuiTableRow-root {
	background-color: #fff !important;
}

.css-my7ez9-MuiButtonBase-root-MuiIconButton-root {
	color: #164c68 !important;
}

.css-19evkbn-MuiDivider-root {
	border-color: rgb(23 77 103 / 24%) !important;
	border-width: 1px !important;
}

.css-y9dlze-MuiPaper-root {
	color: #164c68 !important;
}

.css-yp80rg {
	background-color: #fff !important;
}

.css-67gfy2-MuiTypography-root {
	color: #164c68 !important;
	font-size: 20px !important;
}

.css-8u52my-MuiButtonBase-root-MuiIconButton-root {
	color: #164c68 !important;
}

.css-1d3qusx-MuiTypography-root {
	color: #fff !important;
}

.css-fr0upe-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
	color: #00a3b1 !important;
}

.css-zhmm3j-MuiTypography-root {
	color: #164c68 !important;
}

.css-y9dlze-MuiPaper-root {
	background-color: #fff !important;
}

.css-1ix3oqu::before,
.css-1ix3oqu::after {
	border-top: thin solid #164c68 !important;
}

.css-ii9ivn {
	background-color: #fff !important;
}

.css-167qg84 {
	color: #164c68 !important;
}

.css-pzqaba {
	color: #164c68 !important;
}

.css-1sd2srg {
	color: #164c68 !important;
}

.css-1ti6y9h {
	color: #164c68 !important;
}

.css-ak8zv1 {
	color: #164c68 !important;
}

.css-8srwz {
	color: #fff !important;
}

.css-14e5agy {
	color: rgb(117 117 117) !important;
}

.css-14e5agy.Mui-checked {
	color: #fdfdfd !important;
}

.css-14e5agy.Mui-checked + .MuiSwitch-track {
	background-color: #bdbdbd !important;
}

.css-14du975 {
	color: #fff !important;
}

.css-kswqkt.Mui-disabled {
	background-color: #164c68 !important;
}

.css-11v4jx5 {
	color: #164c68 !important;
}

.css-16nkkkm {
	color: #164c68 !important;
}

.css-7sfyhx {
	border-color: rgb(146 146 146 / 25%) !important;
	border-width: 1px !important;
}

.css-1f7d92k {
	color: #164c68 !important;
}

.css-2gemen {
	color: #164c68 !important;
}

.css-wx0yx4 .MuiOutlinedInput-root .MuiAutocomplete-input {
	padding: 14.5px 4px 7.5px 5px !important;
}

.css-14se5d6.Mui-selected {
	background-color: #00dcff52 !important;
}

.css-14se5d6 {
	color: #164c68 !important;
}

.css-d0cm6k {
	color: #164c68 !important;
}

.css-x2w6db {
	color: #164c68 !important;
}

.css-ak8zv1 {
	color: #fff !important;
}

.css-1tzeee1 {
	color: #fff !important;
}

.css-q5fqw0 {
	min-width: 250px !important;
}

.css-tzi8t {
	fill: #164c68 !important;
}

.css-zvrrc0 {
	fill: #164c68 !important;
}

.css-epvm6 .css-1t3zl64 .css-11fo197 {
	color: #164c68 !important;
}

.css-1pc03ml .css-1t3zl64 .css-11fo197 {
	color: #fff !important;
}

.css-1w9sz8x {
	color: #164c68 !important;
}

.css-1crctjg button {
	color: #fff !important;
}

.css-adx198 li .MuiFormControlLabel-label {
	color: #fff !important;
}
.rs-picker-error > .rs-input-group {
	border-color: #424242 !important;
}

.css-1vhnkep .css-167qg84 {
	color: #fff !important;
}

.css-1sb2q3k,
.css-1ere0xy,
.css-v2ihpk,
.css-1p8t4ly {
	color: #164c68 !important;
}
.css-g5sy4h {
	background-color: #120000 !important;
}

.css-1sc318e {
	color: #164c68 !important;
}
